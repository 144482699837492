<template>
  <div class="treeview-wrapper">
    <div class="treeview-inner">
      <div v-for="(menu, index) in menu" :key="index">
        <div class="treeview-header">
          <h5 class="mb-0">{{ menu.name }}</h5>
          <div class="table-actions text-right">
            <a href="" class="success-icon"
              ><i class="fas fa-pencil-alt"></i
            ></a>
            <a href="" class="reject-icon"><i class="fas fa-trash-alt"></i></a>
          </div>
        </div>
      </div>
      <div class="treeview-footer mt-5 text-center">
        <button class="btn custom-btn-style">Add</button>
      </div>
    </div>
    <div class="treeview-inner">
      <div v-for="(group, index) in group" :key="index">
        <div class="treeview-header">
          <h5 class="mb-0">{{ group.name }}</h5>
          <div class="table-actions text-right">
            <a href="" class="success-icon"
              ><i class="fas fa-pencil-alt"></i
            ></a>
            <a href="" class="reject-icon"><i class="fas fa-trash-alt"></i></a>
          </div>
        </div>
      </div>
      <div class="treeview-footer mt-5 text-center">
        <button class="btn custom-btn-style">Add</button>
      </div>
    </div>
    <div class="treeview-inner">
      <div v-for="(category, index) in category" :key="index">
        <div class="treeview-header">
          <h5 class="mb-0">{{ category.name }}</h5>
          <div class="table-actions text-right">
            <a href="" class="success-icon"
              ><i class="fas fa-pencil-alt"></i
            ></a>
            <a href="" class="reject-icon"><i class="fas fa-trash-alt"></i></a>
          </div>
        </div>
      </div>
      <div class="treeview-footer mt-5 text-center">
        <button class="btn custom-btn-style">Add</button>
      </div>
    </div>
    <div class="treeview-inner">
      <div v-for="(item, index) in item" :key="index">
        <div class="treeview-header">
          <h5 class="mb-0">{{ item.name }}</h5>
          <div class="table-actions text-right">
            <a href="" class="success-icon"
              ><i class="fas fa-pencil-alt"></i
            ></a>
            <a href="" class="reject-icon"><i class="fas fa-trash-alt"></i></a>
          </div>
        </div>
      </div>
      <div class="treeview-footer mt-5 text-center">
        <button class="btn custom-btn-style">Add</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TreeViewTest",
  data() {
    return {
      menu: [
        {
          name: "Karahi"
        },
        {
          name: "Chicken"
        },
        {
          name: "Handi"
        },
        {
          name: "Beef"
        }
      ],
      group: [
        {
          name: "Karahi"
        },
        {
          name: "Chicken"
        },
        {
          name: "Handi"
        },
        {
          name: "Beef"
        }
      ],
      category: [
        {
          name: "Karahi"
        },
        {
          name: "Chicken"
        },
        {
          name: "Handi"
        },
        {
          name: "Beef"
        }
      ],
      item: [
        {
          name: "Karahi"
        },
        {
          name: "Chicken"
        },
        {
          name: "Handi"
        },
        {
          name: "Beef"
        }
      ]
    };
  }
};
</script>

<style scoped></style>
